<template>
  <div class="layoutBorder">
    <div class="layoutHeader">
          <img svg-inline src="@/assets/vector/letoile.svg/">
    </div>
    <div class="layoutBody">
      <div>
        <h1>Карусель скидок!</h1>
        <span>на парфюмерную косметику</span>
      </div>

      <div>
        <h3>8PQK97A4C</h3>
        <p>СКИДКА 8% ОТ 1500Р ДО 31.08</p>
      </div>

      <div>
        <h3>PL7WFRQ3YF</h3>
        <p>СКИДКА 3000Р ОТ 7000Р ДО 31.08</p>
      </div>

      <div>
        <h3>P2031SWMQX</h3>
        <p>СКИДКА 20% ОТ 7500 РУБ С УЧЁТОМ СКИДОК ДО 31.08</p>
      </div>

      <div>
        <h3>PR15VBMN6VV</h3>
        <p>СКИДКА 15% ОТ 3000РУБ С УЧЁТОМ СКИДОК НА 15% ДО 31.08</p>
      </div>
    </div>

    <div class="layoutFooter">
      <button v-on:click="toShop">За покупками!</button>
    </div>


  </div>
</template>

<script>
export default {
  name: "promoPageLetoile",
  methods: {
    toShop() {
      window.open( 'https://www.letu.ru/', '_blank' )
    }
  },
}
</script>

<style scoped>
.layoutBody { text-align: center }
h1 { margin-bottom: 0 }
span { font-weight: bold }
p { font-size: 3.53vw; font-weight: bold }
.layoutFooter { display: flex; justify-content: center }
@media screen and (min-width: 760px) {
  h1 { margin-bottom: 0.3vw }
  img { width: 27.5vw }
  .layoutHeader { display: flex; justify-content: center }
  p { font-size: 0.97vw }
}
@media screen and ( min-width: 1240px ) {
  h1 { margin-bottom: 10px }
  img { width: 400px }
  p { font-size: 12px }
}
</style>